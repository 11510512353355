body.cms-menu-visible header {
    transform: translateY(50px);
}

header {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999;
    height: 180px;
    padding: 0;
    margin: 0;
    background: none;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.13);

    section.top {
        height: 40px;
        background: darken(@colour-primary, 10%);
        line-height: 38px;
        color: #fff;
        overflow: hidden;

        a {
            display: inline-block;
            margin-left: 10px;
            color: #fff;
            text-decoration: none;

            &.btn-default {
                color: #555;
            }
        }

        .btn-default {
            margin-top: -2px;
        }
    }

    section.bottom {
        height: 140px;
        background: #fff;

        #logo {
            position: absolute;
            top: 0px;
            left: 50%;
            margin-left: -570px;
            width: 336px;
            height: 140px;
            z-index: 1000;

            h1 {
                width: 100% !important;
                height: 140px;
                margin: 0;

                a {
                    height: 100% !important;
                    width: 100% !important;
                    display: block;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;

                    span {
                        display: none;
                    }
                }
            }

            @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                margin-left: -485px;
                width: 350px;

                h1 {
                    max-width: 350px;

                    a {
                        max-width: 350px !important;
                        background-size: contain !important;
                    }
                }
            }

            @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                margin-left: ((@screen-sm-min / 2) * -1) + (@grid-gutter-size / 2);
                width: 350px;

                h1 {
                    max-width: 350px;

                    a {
                        max-width: 350px !important;
                        background-size: contain !important;
                    }
                }
            }

            @media (max-width: @screen-xs-max) {
                left: 15px;
                margin-left: 0;
                width: 230px !important;

                h1 {
                    max-width: 230px;

                    a {
                        max-width: 230px !important;
                        background-size: contain !important;
                    }
                }
            }
        }

        div.container {
            position: relative;

            #main-nav {
                position: absolute;
                top: 36px;
                left: auto;
                right: 0px;
                width: 715px;
                z-index: 10;

                @media (max-width: 991px) {
                    width: 100%;
                    top: 125px;
                    left: 0px;

                    .navbar {
                        position: relative;
                        overflow: visible;
                        width: 100%;

                        .navbar-collapse {
                            overflow: hidden;
                            position: absolute;
                            width: 100%;
                            height: inherit;
                            top: 104px;
                            border-top: 0;
                            transition: height 0.35s ease 0s;

                            &.in {
                                overflow: visible;
                            }

                            .navbar-nav {
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                height: auto;
                                width: 100%;
                                margin: 0px;

                                z-index: 1000;

                                li {
                                    a {
                                        background: @dropdown-back-colour !important;
                                        color: @dropdown-fore-colour;
                                        line-height: 48px;
                                        padding: 0px 20px;
                                        border-bottom: none !important;

                                        &:hover {
                                            background: @dropdown-hover-back-colour !important;
                                            color: @dropdown-hover-fore-colour;
                                        }

                                        &::after {
                                            content: none;
                                        }
                                    }


                                    &:last-child {
                                        a {
                                            margin: 0px (@navbar-item-padding - 3px);
                                        }
                                    }

                                    &.active {
                                        a {
                                            background: @dropdown-active-back-colour !important;
                                            color: @dropdown-active-fore-colour !important;

                                            &:hover {
                                                background: @dropdown-hover-back-colour !important;
                                                color: @dropdown-hover-fore-colour !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .navbar {
                    .navbar-brand {
                        position: relative;
                        display: block;
                        background: none;
                        padding: 0px 3px;
                        margin: 0px (@navbar-item-padding - 3px) 0px 0px;
                        font-size: 18px;
                        letter-spacing: 0px;

                        &::after {
                            content: '';
                            position: absolute;
                            display: block;
                            bottom: 10px;
                            left: 50%;
                            width: 0%;
                            margin-left: 0%;
                            height: 2px;
                            background: @navbar-active-fore-colour;

                            transition: width 200ms ease, margin-left 200ms ease;
                        }

                        &:hover, &:focus {
                            background: none;

                            &::after {
                                width: 100%;
                                margin-left: -50%;
                            }
                        }

                        &.navbar-brand-active {
                            background: none;
                            font-weight: bold;

                            &:hover, &:focus {
                                color: @navbar-active-fore-colour !important;
                                background: none;
                            }
                        }
                    }

                    .navbar-toggle {
                        background: @heading-colour;
                        right: 15px;
                        width: 50px;

                        .icon-bar {
                            background: #fff;
                        }
                    }

                    .navbar-nav {
                        li {
                            a {
                                position: relative;
                                display: block;
                                background: none;
                                padding: 0px 3px;
                                margin: 0px (@navbar-item-padding - 3px);
                                font-size: 18px;
                                letter-spacing: 0px;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    display: block;
                                    bottom: 10px;
                                    left: 50%;
                                    width: 0%;
                                    margin-left: 0%;
                                    height: 2px;
                                    background: @navbar-active-fore-colour;

                                    transition: width 200ms ease, margin-left 200ms ease;
                                }

                                &:hover, &:focus {
                                    background: none;

                                    &::after {
                                        width: 100%;
                                        margin-left: -50%;
                                    }
                                }
                            }

                            &:last-child {
                                a {
                                    margin-right: 0px;
                                }
                            }

                            &.active {
                                a {
                                    background: none;
                                    font-weight: bold;

                                    &:hover, &:focus {
                                        color: @dropdown-hover-fore-colour;
                                        background: none;
                                    }
                                }
                            }
                        }
                    }
                }

                @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                    width: 580px;
                }
            }
        }
    }
}

section.banner {
    position: relative;
    margin-bottom: 40px;

    div#carousel-home {
        position: relative;
        min-height: 200px;

        .carousel-inner {
            overflow: hidden;

            .item {
                min-width: 700px;
                opacity: 0;
                transition: opacity 1s ease;
                -webkit-transition: opacity 1s ease;
                transform: none;

                img {
                    max-width: 105%;
                    min-height: 200px;
                }
            }

            .active {
                opacity: 1;
            }

            .active.left,
            .active.right {
                left: 0;
                opacity: 0;
            }

            .next.left,
            .next.right {
                opacity: 1;
            }

            .prev.left,
            .prev.right {
                opacity: 1;
            }
        }

        .carousel-indicators {
            z-index: 3;
            text-align: right;
            .no-select();
            bottom: 30px !important;

            li {
                width: 12px;
                height: 12px;
                margin: 0px 3px;

                background: rgba(255, 255, 255, 0.5);
                border: 1px solid rgba(255, 255, 255, 0.2);

                transition: background-color 300ms ease, border-color 300ms ease;
                -webkit-transition: background-color 300ms ease, border-color 300ms ease;

                &:hover {
                    background: #ffffff;
                    border: 1px solid #ffffff;
                }

                &.active {
                    background: lighten(@colour-primary, 15%);
                    border: 1px solid lighten(@colour-primary, 15%);
                }
            }
        }

        .carousel-caption {
            position: absolute;
            z-index: 5;
            font-family: @heading-font, @heading-typography;
            font-size: 68px;
            text-align: right;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
            line-height: 60px;
        }
    }

    div#page-background {
        overflow: hidden;
        background: #000;

        img {
            opacity: 0.7;
            margin: 0 auto;
        }

        @media (max-width: 991px) {
            height: 200px;
        }
    }

    div.carousel-shadow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;
        height: 100%;

        background: -webkit-linear-gradient(top,  rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 10%,rgba(0,0,0,0) 85%,rgba(0,0,0,0.2) 100%);
        background: linear-gradient(to bottom,  rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 10%,rgba(0,0,0,0) 85%,rgba(0,0,0,0.2) 100%);
    }
}

.call-to-actions {
    margin-top: -100px;
    z-index: 1000;

    .action {
        height: 200px;
        width: 100%;

        border-top: 5px solid @colour-primary;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
        overflow: hidden;
        margin-bottom: 20px;

        img {
            display: block;
            margin-left: -3px;
            margin-top: 15px;
        }

        h3 {
            margin: 10px 0 5px;
            text-align: right;
        }

        p {
            font-size: 14px;
            line-height: 22px;
            text-align: right;
        }
    }
}
