footer {
    font-size: 14px;
    line-height: 20px;
    padding: 0;

    h4 {
        color: #fff;
    }

    img {
        vertical-align: middle;
    }

    .text {
        padding: 15px;

        @media (max-width: @screen-sm-max) {
            text-align: center !important;
        }
    }

    a {
        color: @colour-primary;

        &:hover,
        &:focus {
            color: @footer-fore-colour;
            text-decoration: none;
        }
    }

    .footer-logo {
        display: inline-block;
        opacity: 0.5;
        margin: 10px auto 30px;
    }

    .footer-social {
        display: inline-block;
        font-size: 2em;
        margin-left: 10px;
        color: softlight(@footer-back-colour, #ffffff);

        &:hover,
        &:focus {
            color: #fff;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    .footer-sectors {
        list-style: none;
        padding-left: 0;

        li {
            display: block;
            padding: 6px 0px;
            border-bottom: 1px solid softlight(@footer-back-colour, #555555);

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }

            a {
                display: block;
                text-decoration: none;
            }
        }
    }
}

@media (max-width: @screen-sm-max) {
    footer > .container > .row > .col-xs-12:nth-child(2) {
        background: darken(@footer-back-colour, 2%);
    }
}
