#main-nav nav {
    float: right;

    .navbar-brand {
        margin-right: 0px !important;
        float: left;
    }

    .navbar-collapse {
        float: right;
    }
}

.submenu.nav-pills {
    margin-top: 0;

    li.depth-1 a {
        padding: 7px 15px;
        font-size: 0.9em;
        background: none;
        color: @main-fore-colour;
    }

    li.depth-1.active a {
        color: @colour-primary;
    }
}

#main-nav .navbar .navbar-nav > li > a {
    &:hover, &:focus {
        color: @navbar-active-fore-colour !important;
    }
}

#main-nav .navbar .navbar-nav li.dropdown .dropdown-menu {
    border: 1px solid darken(@dropdown-back-colour, 10%);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);

    li > a {
        margin: 0 !important;
        padding: 0 10px !important;
        font-weight: normal !important;
        line-height: 48px;

        &:hover, &:focus {
            color: @dropdown-hover-fore-colour !important;
        }

        &::after {
            content: none !important;
        }

        @media (max-width: 991px) {
            margin: 0 10px !important;
            padding: 0 20px !important;
        }
    }

    li.active > a {
        background: @dropdown-active-back-colour !important;
        color: @dropdown-active-fore-colour !important;

        &:hover, &:focus {
            color: @dropdown-active-fore-colour !important;
        }
    }
}
