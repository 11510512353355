/*
ABWEB CMS STYLE CONFIGURATION

This LESS file contains many variables used to make deploying styles to a new installation of ABWeb CMS easy. It's best to use this file to cover as much
group style-wise as possible, before editing the style.less file for additional changes and extra elements not covered in ABWeb CMS
*/

/*--------------------- MAIN */

@main-back-colour: #f0f2f4;
@main-fore-colour: #555555;

@colour-primary:                    #00A9E0;
@colour-success:                    #3eb61a;
@colour-info:                       #90a0e4;
@colour-warning:                    #d18b13;
@colour-danger:                     #c4262a;
@color-primary-dark:				#044c61;

@border-radius-size: 0px;
@canvas-colour: @main-back-colour;

/*--------------------- TYPOGRAPHY */

@use-google-fonts: true;                // If true, will use an external Google Fonts stylesheet to provide the fonts below

@content-font: 'Oxygen';
@content-typography: sans-serif;
@content-normal-weight: 400;
@content-bold-weight: 700;
@content-size: 16px;                    // This represents the default size of text on the page. All other font sizes are derived from this.

@heading-font: 'Arimo';
@heading-typography: sans-serif;
@heading-weight: 400;
@heading-colour: @colour-primary;               // Used on H1, H3 and H5 tags
@heading-alt-colour: #333;           // Used on H2, H4 and H6 tags

@include-additional-font:           false;                  // If you wish to include a third font, set this to true and set the values below
@additional-font:                   'Cinzel';
@additional-typography:             serif;
@additional-normal-weight:          400;
@additional-bold-weight:            700;
@additional-include-italic-font:    false;                  // Set to true to load the italic and bold-italic version of this font as well

@monospace-font: 'Droid Sans Mono';
@monospace-typography: monospace;

/*--------------------- HEADER */

@header-back-colour: #ffffff;
@header-fore-colour: #333333;
@header-height: 140px;

@header-show-top-border: false;         // A border at the top of the page helps users with touch devices by giving them feedback showing they are at
                                        // the top of the page. Set to false to disable this. (deprecated)
@header-top-border-colour: #541C51;

@header-use-logo: true;                // When true, the H1 tag in the header will be replaced with a site logo that can be specified below
@header-logo-image: 'westralian.png';                 // Image file to use as logo. Must reside in the /app/webroot/img folder or one of its subfolders
@header-logo-position: 'left';        // Set the alignment of the logo or site name (use left, right or centre)
@header-logo-width: 336px;
@header-logo-height: 42px;
@header-vertical-gutter: 59px;

@header-use-background-image: false;     // When true, the background of the header will be an image resized to fit within the constrains of the header
@header-background-image: 'included/lightswirl.jpg';           // Image file to use as background. Must reside in the /app/webroot/img folder or one of its subfolders

/*--------------------- HOME PAGE */

@home-page-jumbo: false;                 // If true, will resize the header area to be large on the front page for more visual impact
@home-page-header-height: 80px;
@home-page-header-logo-width: 336px;
@home-page-header-logo-height: 42px;
@home-page-header-vertical-gutter:  0px;

/*--------------------- NAVIGATION */

@navbar-position: 'top';
@navbar-back-colour: transparent;
@navbar-fore-colour: #000000;
@navbar-height: 62px;
@navbar-item-padding: 13px;
@navbar-font-size: 1em;
@show-brand: true;                     // Brand is usually used to show a "Home" page link, but could also contain the site name

@navbar-hover-back-colour: #ffffff;
@navbar-hover-fore-colour: #000000;

@navbar-active-back-colour: #ffffff;
@navbar-active-fore-colour: #0083AD;

// Dropdown (hover and active states copied from main nav)
@dropdown-back-colour: #0083AD;
@dropdown-fore-colour: #ffffff;

@dropdown-hover-back-colour: #0083AD;
@dropdown-hover-fore-colour: #000000;

@dropdown-active-back-colour: darken(@dropdown-back-colour, 15%);
@dropdown-active-fore-colour: #ffffff;

// Submenu found on page
@submenu-back-colour: #dadada;
@submenu-fore-colour: #333333;

@submenu-hover-back-colour: contrast(@submenu-back-colour, lighten(@submenu-back-colour, 15%), darken(@submenu-back-colour, 15%));
@submenu-hover-fore-colour: contrast(@submenu-hover-back-colour);

@submenu-active-back-colour: #979b4c;
@submenu-active-fore-colour: #ffffff;

/*--------------------- FOOTER */

@sticky-footer: true;                   // If true, the footer will stick to the bottom of the page

@footer-back-colour: #013f52;
@footer-fore-colour: #e3e3e3;
@footer-height: 270px;

/*--------------------- LINKS */

@link-colour: #15345a;
@link-hover-colour: #000000;
@link-show-underline: false;

/*--------------------- FORMS */

@form-fore-colour: @main-fore-colour;
@form-back-colour: #fafafa;
@form-border-colour: darken(@form-back-colour, 25%);

@select2-hover-fore-colour: #fff;
@select2-hover-back-colour: #5897fb;

@select2-selected-fore-colour: @form-fore-colour;
@select2-selected-back-colour: darken(@form-back-colour, 5%);
@select2-selected-border-colour: #57bf2d;

/*--------------------- ABWEB CMS */

@abweb-orange: #E5A220;
@abweb-grey: #444444;
@abweb-light-grey: #C7CBCD;

/*--------------- ABWEB CMS INTERNALS */

@abwebmenu-back-colour: #282828;
@abwebmenu-fore-colour: #dddddd;
@abwebmenu-active-back-colour: #444444;
@abwebmenu-active-fore-colour: #ffffff;

@abweb-table-border: #d5d5d5;
@abweb-table-th-back: #EBEDF4;
@abweb-table-hover: #edf5f7;

/*--------------------- UTILITIES */

@flat-mode: true;                       // If true, this will remove a lot of the gradients, border radii and shadows used on various
                                        // Bootstrap components, to give a flat look

@debranded: false;                       // If true, any styles and logos that make ABWeb CMS branded to ABWeb will be removed
