.article-grid .article-grid-container {
    background: #fff;
    border: 1px solid #ddd;
    height: 420px;
}

.news {
    .article {
        position: relative;
        height: 350px;
        width: 100%;

        border-top: 5px solid @colour-primary;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
        overflow: hidden;
        padding: 10px 20px;
        margin-bottom: 30px;
        text-align: left;

        .image {
            margin-bottom: 10px;
            text-align: center;

            img {
                margin: 0 auto;
            }
        }

        .title h2 {
            margin: 0 0 10px 0;

            a {
                color: @colour-primary;
                font-size: 28px;

                &:hover,
                &:focus {
                    color: @main-fore-colour;
                    text-decoration: none;
                }
            }
        }

        .description {
            font-size: 14px;
            line-height: 20px;
        }

        .date {
            position: absolute;
            bottom: 13px;
            left: 25px;
            color: #999;
            font-size: 14px;
        }

        .action {
            position: absolute;
            bottom: 10px;
            right: 10px;
            width: 130px;

            &.full-width {
                left: 0px;
                right: auto;
                width: 100%;
                padding: 0px 10px;
            }
        }
    }
}

.article-content {
    .article-thumbnail {
        margin: 10px;
    }
}
