#wrap .table {
    &.abwebcms-table {
        border-bottom: 1px solid darken(@colour-primary, 10%);
        border-right: 1px solid darken(@colour-primary, 10%);

        tr th {
            border-top: none !important;
            border-left: 1px solid rgba(255, 255, 255, 0.07);
            background: darken(@colour-primary, 10%);
            color: #fff;

            &:first-child {
                border-left: none;
            }

            a {
                color: #ffd337;

                &:hover,
                &:focus {
                    color: #fff;
                }
            }

            .glyphicon {
                opacity: 0.5;
            }
        }

        tr {
            td {
                border-left: 1px solid rgba(0, 0, 0, 0.07);
                border-top: 1px solid rgba(0, 0, 0, 0.07);

                &:first-child {
                    border-left: 1px solid darken(@colour-primary, 10%);
                }
            }

            &:first-child td {
                border-top: 1px solid darken(@colour-primary, 10%) !important;
            }
        }
    }
}
