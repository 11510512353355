// Include default ABWeb CMS public styles
@import '../../../../../../webroot/assets/src/less/default.less';
// Custom variables
@import (optional) 'variables.less';
// Animations
@import (optional) 'animations.less';

@import 'colorbox.less';
@import 'elements/sections.less';
@import 'elements/tabs.less';
@import 'elements/header.less';
@import 'elements/footer.less';
@import 'elements/buttons.less';
@import 'elements/tables.less';
@import 'elements/nav.less';
@import 'elements/articles.less';

/* -- Add site-specific styling below -- */

body {
    perspective: none;
    padding-top: 179px !important;
    min-height: 100%;
    height: auto;

    &.cms-menu-visible {
        padding-top: 229px !important;
    }
}

.wow {
    visibility: hidden;
}

h1, h2, h3, h4, h5, h6 {
    font-family: @heading-font, @heading-typography;
}

#wrap > .container > .page-header:first-child {
    margin-top: 40px;
}

h1:first-child,
h2:first-child {
    margin-top: 0;
}

h1.page-header,
div.page-header h1 {
    padding: 5px 0px 10px 0px;
    line-height: 31px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    font-family: @heading-font, @heading-typography;
    font-size: 30px;
}

h2.page-header {
    padding: 5px 0px 10px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    font-family: @heading-font, @heading-typography;
    font-size: 24px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: -1px;
}

div.page-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-bottom: 0;

    h1 {
        border-bottom: none;
        padding: 0px;
        margin: 10px 0px;
    }

    h1 ~ p {
        margin-top: -10px;
        margin-bottom: 10px;
    }
}

div#wrap {
    padding: 0 0 @footer-height;
}

.well {
    border-radius: 8px;
    border: none;
    background: darken(@main-back-colour, 5.5%);
}

hr {
    border-top-color: rgba(0, 0, 0, 0.15);
}

.actions {
    background: lighten(@main-back-colour, 5.5%);
    padding: 15px;
    margin-bottom: 40px;
    border-radius: 8px;
}

.testimonials,
.inner-testimonials {
    .testimonial {
        color: @heading-alt-colour;
        font-style: italic;
    }

    .testimonial-author {
        color: @heading-colour;
        margin-top: 10px;
    }
}

.inner-testimonials {
    margin-bottom: 15px;
}

.portfolio-slider {
    margin: 0 auto 20px auto;

    .slick-prev,
    .slick-next {
        width: 33px;
        height: 45px;
        position: absolute;
        top: 40%;
        left: -13px;
        overflow: hidden;
        text-indent: -9000px;
        z-index: 1000;

        border: none;
        background: url('/img/slide-previous-arrow.png') no-repeat top left;
    }

    .slick-next {
        left: auto;
        right: -8px;
        background: url('/img/slide-next-arrow.png') no-repeat top left;
    }

    .image {
        padding: 0px 5px 0px 0px;

        &.slick-active:last-of-type {
            padding: 0px;
        }
    }
}
.media {
    position: relative;
    overflow: visible;

    .media-item {
        position: relative;
        margin-bottom: 10px;
        cursor: pointer;

        .image {
            background: contrast(@main-back-colour, lighten(@main-back-colour, 2%), darken(@main-back-colour, 2%));
            border: 1px solid contrast(@main-back-colour, lighten(@main-back-colour, 14%), darken(@main-back-colour, 14%));
            width: 124px;
            height: 210px;
            padding: 1px;
            margin: 0 auto;

            canvas {
                opacity: 0.4;
                transition: opacity 0.75s ease;
                display: block;
            }

            &.uploaded canvas {
                opacity: 1;
            }
        }

        .name {
            background: @heading-colour;
            color: contrast(@heading-colour, @main-fore-colour, spin(@main-fore-colour, 180));
            font-size: 0.7em;
            text-align: center;
            line-height: 18px;
            height: 18px;
            margin: 1px 0px;
            overflow: hidden;
        }

        .tags {
            background: contrast(@main-back-colour, lighten(@main-back-colour, 20%), darken(@main-back-colour, 20%));
            height: 40px;

            .icon {
                height: 30px;
                padding: 10px 0px;
                width: 30px;

                float: left;
                text-align: center;
                color: contrast(@main-back-colour, lighten(@main-back-colour, 8%), darken(@main-back-colour, 8%));
            }

            .list {
                display: block;
                width: 90px;
                height: 32px;
                padding: 5px 0px;
                line-height: 0.65em;
                overflow: hidden;

                .label {
                    display: inline-block;
                    font-size: 0.65em;
                    padding: 1px 3px;
                    background: contrast(@main-back-colour, lighten(@main-back-colour, 50%), darken(@main-back-colour, 50%));
                    border-radius: 5px;
                    font-weight: normal;
                }
            }
        }

        .time {
            background: darken(@colour-primary, 10%);
            color: contrast(darken(@colour-primary, 10%));
            height:  25px;
            margin: 1px 0;

            &.unpublished {
                background: darken(@colour-warning, 10%);
                color: contrast(darken(@colour-warning, 10%));

                .icon {
                    color: contrast(darken(@colour-warning, 10%), lighten(darken(@colour-warning, 10%), 20%), darken(darken(@colour-warning, 10%), 20%));
                }
            }

            &.expired {
                background: darken(@colour-danger, 10%);
                color: contrast(darken(@colour-danger, 10%));

                .icon {
                    color: contrast(darken(@colour-danger, 10%), lighten(darken(@colour-danger, 10%), 20%), darken(darken(@colour-danger, 10%), 20%));
                }
            }

            .icon {
                height: 25px;
                padding: 2px 0px;
                width: 30px;

                float: left;
                text-align: center;
                color: contrast(darken(@colour-primary, 10%), lighten(darken(@colour-primary, 10%), 20%), darken(darken(@colour-primary, 10%), 20%));
            }

            .status {
                display: inline-block;
                font-size: 0.65em;
                padding: 1px 3px;
                border-radius: 5px;
                font-weight: normal;
                float: left;
                margin: 1px 0px;
                line-height: 10px;
            }
        }

        &:hover .image {
            border: 1px solid @colour-primary;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        }

        &.ui-sortable-helper {
            .image {
                border: 1px solid @colour-primary;
                box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
            }
        }

        .loader {
            position: absolute;
            bottom: 10px;
            left: 50%;
            margin-left: -52px;
            width: 104px;
            height: 10px;
            border: 1px solid #333;
            background: #fff;

            .loaded {
                width: 0px;
                height: 8px;
                background: #449D44;
            }
        }
    }

    .media-item-placeholder {
        position: relative;
        margin-bottom: 10px;
        background: #F4F2FF;
        border: 1px dashed #D1D2E0;
        height: 210px;
        padding: 1px;
    }
}

.image-form-body {
    font-size: 0.9em;

    .form-group {
        margin-bottom: 0;
    }

    .form-control {
        font-size: 0.9em;
    }

    label {
        margin-top: 8px;
    }

    .image-preview {
        .image {
            background: #F4F2FF;
            border: 1px solid #66afe9;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
            padding: 1px;
        }
    }

    .image-details {
        .row {
            margin-bottom: 5px;
        }
    }

    .select2-container .select2-selection {
        padding: 0;
    }
}

#media-help {
    .glyphicon.side {
        font-size: 5em;
        color: #aaa;
    }
}

.gallery-grid {
    .media-item {
        margin-bottom: 10px;

        a {
            display: block;
        }

        img {
            background: #fff;
            border: 1px solid #E0E0E0;
            padding: 1px;
            margin: 0 auto;

            &:hover {
                border: 1px solid @colour-primary;
                box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.gallery-tags {
    .tag {
        .flatten();
        display: block;
        padding: 10px 15px;
        margin-top: 2px;
        cursor: pointer;
        text-decoration: none;
        background: @submenu-back-colour;
        color: @submenu-fore-colour;

        &:hover {
            background: @submenu-hover-back-colour;
            color: @submenu-hover-fore-colour;
        }
    }

    .tag.active {
        background: @submenu-active-back-colour;
        color: @submenu-active-fore-colour;

        &:hover {
            background: @submenu-hover-back-colour;
            color: @submenu-hover-fore-colour;
        }
    }
}

.abweb-modal {
    z-index: 2002;
}

.client-image {
    margin: 30px auto;
}

@import 'animations.less';

.select2-container {
    width: auto !important;
}
