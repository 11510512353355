.nav-tabs {
    border-bottom: 0;

    & > li {
        margin-bottom: 0px;

        a {
            border: none !important;
        }

        a:hover,
        a:focus {
            border-radius: 8px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background: darken(@main-back-colour, 10%);
        }
    }

    & > li.active a {
        border: none;
        background: #fff;
        border-radius: 8px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &:focus,
        &:hover, {
            background: #fff !important;
            border: none !important;
        }
    }
}

.tab-content {
    .tab-pane {
        background: #fff;
        border: none;
        border-radius: 8px;
        border-top-left-radius: 0;
        padding: 30px;
    }
}

section.alternate-content {
    .tab-content .tab-pane {
        background: @main-back-colour;
    }

    .nav-tabs {
        & > li.active a,
        & > li.active a:hover,
        & > li.active a:focus {
            background: @main-back-colour !important;
        }
    }
}
