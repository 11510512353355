.btn {
    border-radius: 15px;
    border: none;
    outline: none !important;

    &.btn-xs {
        padding: 2px 10px;
    }

    &.btn-labeled {
        .btn-label {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }

        &.btn-block {
            .btn-label {
                padding: 10px 5px;
                left: 0px;
                background: none;
            }
        }
    }

    &.btn-contact {
        display: inline-block;
        vertical-align: middle;
        background: @heading-colour;
        color: #fff;
        text-transform: uppercase;

        &.btn-large-icon {
            .glyphicon,
            .social {
                font-size: 1.5em;
                top: 0px;
            }
        }
    }

    &.btn-primary-dark {
        background: @color-primary-dark;
    }
    &.btn-primary-dark:hover {
        color:#fff;
        background: darken(@color-primary-dark, 5%);
    }
}

.btn-group .btn,
.input-group .btn {
    border: 1px solid @form-border-colour;
}
