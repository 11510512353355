section.content,
section.alternate-content,
section.third-content,
section.page-heading {
    padding: 40px 0px;

    &.centered {
        text-align: center;
    }

    @media (max-width: @screen-xs-max) {
        div[class^="col-"] ~ div[class^="col-"] {
            padding-top: 8px;
        }

        div[class^="col-"]:first-child {
            padding-top: 0;
        }
    }
}

section.alternate-content {
    background: #fff;
}

section.third-content {
    background: desaturate(darken(@colour-primary, 15%), 10%);
    color: #fff;

    h1.page-header,
    div.page-header h1 {
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        margin: 10px 0;
    }

    div.page-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);

        h1 {
            border-bottom: none;
        }
    }

    .quote {
        position: relative;

        .content {
            position: relative;
            padding: 20px 50px;
            background: darken(desaturate(darken(@colour-primary, 15%), 10%), 3%);
            border-radius: 40px;

            p:last-child {
                margin-bottom: 0;
            }

            &::before,
            &::after {
                position: absolute;
                display: block;
                width: 30px;
                height: 0px;
                color: lighten(desaturate(darken(@colour-primary, 15%), 10%), 15%);
                font-size: 72px;
                font-weight: bold;
            }

            &::before {
                content: '“';
                top: 25px;
                left: 15px;
            }

            &::after {
                content: '”';
                bottom: 25px;
                right: 15px;
            }
        }

        .author {
            text-align: right;
            padding: 13px 50px 10px;
            color: #fcb448;
            font-size: 1.1em;

            & > * {
                display: block;
                margin-top: -3px;
            }
        }
    }
}

section.page-heading {
    background: @colour-primary;
    color: #fff;

    h1 {
        margin: 0;
        color: #fff;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
    }
}

section.crumbs {
    background: #e7ecf1;
    padding: 0;
    height: 42px;

    .breadcrumb {
        margin: 0;
        padding: 0px;
        background: none;
        height: 42px;
        overflow: hidden;

        li {
            height: 42px;
            line-height: 42px;
            display: inline-block;

            &.home a span.glyphicon {
                top: 13px;
                color: darken(@colour-primary, 10%);
            }

            a {
                color: darken(@colour-primary, 10%);
            }
        }

        li ~ li {
            position: relative;
            padding-left: 26px;

            &::before {
                content: '';
                position: absolute;
                display: block;
                height: 100%;
                width: 1px;
                background: darken(#e7ecf1, 5%);
                padding: 0;
                left: 13px;
            }
        }
    }
}

section.abwebcms-content {
    & > .container > .page-header:first-child {
        display: none;
    }
}
